import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import SupDef0 from "../../images/sup-def-0.png";
import SupDef1 from "../../images/sup-def-1.png";
import SupDef2 from "../../images/sup-def-2.png";

export default function SupremeDefender() {
  return (
    <Layout>
      <Helmet>
        <title>Supreme Defender Franchise | Nathan Pasko</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="project-page">
        <ul className="horizontal-scroll">
          <li>
            <picture>
              <img
                src={SupDef2}
                height=""
                width=""
                alt="screenshot"
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                src={SupDef0}
                height=""
                width=""
                alt="screenshot"
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                src={SupDef1}
                height=""
                width=""
                alt="screenshot"
              />
            </picture>
          </li>
        </ul>
        <h1>Supreme Defender Franchise</h1>
        <p>
          My character Defenderman has appeared in a few of my past works, such
          as handful of short comics, and a prop version of his helmet that I
          made. Inspired by tokusatsu movies and TV, Defenderman and his
          sidekick Jr. Defenderman guard Tokyo from invasive aliens and
          monsters. Though Defenderman strives to live a peaceful life, he is
          often pushed to the point of violence in order to protect the citizens
          of Tokyo.
        </p>
        <p>
          In Supreme Defender Franchise, the player controls Defenderman and
          must collect powerups and battle monsters in a never-ending film
          franchise. Whether the player wins or loses each round, the next movie
          is just around the corner.
        </p>
        <p>
          The game features a more hand-drawn art style than some of my other
          pixely games, and imitates some aspects of manga and other black and
          white comics. Though most of Supreme Defender Franchise sticks to one
          of these colors, I added a green midway through development to soften
          the overall tone of the game. Sometimes I appropriate the "rules" of
          past generations of games in order to create a feeling of nostalgia or
          an homage, but it's important for me to break away from these rules
          when they prove restrictive to the point where good opportunities can
          be missed.
        </p>
        <iframe frameborder="0" src="https://itch.io/embed/285143?border_width=0&amp;fg_color=000&amp;link_color=e0e0e0&amp;border_color=fff" width="206" height="165"><a href="https://avknights.itch.io/supreme-defender-franchise">Supreme Defender Franchise by A.V. Knights</a></iframe>      </div>
    </Layout>
  );
}
